.slick-next {
    right:5px;
    z-index: 1;
}

.slick-prev {
    left:5px;
    z-index: 1;
}

.slick-dots {
    bottom: 10px;
}