.slick-next {
    right:5px;
    z-index: 1;
}

.slick-prev {
    left:5px;
    z-index: 1;
}

.slick-dots {
    bottom: 10px;
}
@font-face {
  font-family: "Bosch Sans";
  src: url("/assets/BoschSans-Light.woff") format('woff');
}

@font-face {
  font-family: "Bosch Sans Regular";
  src: url("/assets/BoschSans-Regular.woff") format('woff');
}

@font-face {
  font-family: "Bosch Sans Italic";
  src: url("/assets/BoschSans-LightItalic.woff") format('woff');
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  background:#fff; 
}
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box;
 }
  @media only screen and (max-width: 56.25em) {
    body {
      padding: 0; } }

::selection {
  background-color: #009ecf;
  color: #fff; }

body {
  font-family: "Bosch Sans", Helvetica, Arial, sans-serif;
  /* font-size: 16px; */
  line-height: 1.375;
  color: #000;
  font-weight: 400;
}


